import Vue from "vue";
import Router from "vue-router";
import auth from "./middleware/auth";
import log from "./middleware/log";
import { AclRule } from "vue-acl";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        // {
        //   path: "/",
        //   name: "home",
        //   component: () => import("./views/Home.vue"),
        //   meta: {
        //     middleware: auth,
        //     rule: "public"
        //   }
        // },
        {
          path: "/",
          redirect: "/package",
          // name: "home",
          // component: () => import("./views/Home.vue"),
          // meta: {
          //   middleware: auth,
          //   rule: "public"
          // }
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("./views/pages/Profile.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Profile", active: true },
            ],
            pageTitle: "Profile",
            rule: "public",
            middleware: auth,
          },
        },
        {
          path: "/package",
          name: "package",
          component: () => import("@/views/pages/Package/DataTable.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Package", active: true },
            ],
            pageTitle: "Packages",
            rule: new AclRule("ADMIN")
              .or("ACCOUNTANT")
              .or("SUBACCOUNTANT")
              .or("DRIVER")
              .or("COLLECTOR")
              .or("SUBDRIVER")
              .or("SUBCUSTOMER")
              .or("HOUSEKEEPER")
              .or("BACKOFFICE")
              .or("SUPPORT")
              .or("CUSTOMER")
              .or("COMMERCIAL")
              .generate(),
            middleware: auth,
          },
        },
        {
          path: "/charts",
          name: "charts",
          component: () => import("@/views/pages/Charts/index.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "charts", active: true },
            ],
            pageTitle: "Charts",
            rule: new AclRule("ADMIN")
              .or("SUPPORT")
              .or("CUSTOMER")
              .or("DRIVER")
              .generate(),
            middleware: auth,
          },
        },
        {
          path: "/tickets",
          name: "tickets",
          component: () => import("@/views/pages/Tickets/index.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Claims - Réclamations", active: true },
            ],
            pageTitle: "Claims - Réclamations",
            rule: new AclRule("ADMIN")
              .or("DRIVER")
              .or("SUBDRIVER")
              .or("HOUSEKEEPER")
              .or("BACKOFFICE")
              .or("SUPPORT")
              .or("CUSTOMER")
              .generate(),
            middleware: auth,
          },
        },
        {
          path: "/stock",
          name: "stock",
          component: () => import("@/views/pages/Product/index.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Stock", active: true },
            ],
            pageTitle: "Stock",
            rule: new AclRule("ADMIN")
              .or("HOUSEKEEPER")
              .or("BACKOFFICE")
              .or("SUPPORT")
              .or("CUSTOMER")
              .generate(),
            middleware: auth,
          },
        },
        {
          path: "/logs",
          name: "logs",
          component: () => import("@/views/pages/Logs/DataTable.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Logs", active: true },
            ],
            pageTitle: "Logs",
            rule: new AclRule("ADMIN").generate(),
            middleware: auth,
          },
        },
        {
          path: "/statistics",
          name: "statistics",
          component: () => import("@/views/pages/Statistics/index.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Statistics", active: true },
            ],
            pageTitle: "Statistics",
            rule: new AclRule("ADMIN").generate(),
            middleware: auth,
          },
        },
        {
          path: "/service",
          name: "service",
          component: () => import("@/views/pages/Service/index.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Service", active: true },
            ],
            pageTitle: "Service",
            rule: new AclRule("ADMIN").generate(),
            middleware: auth,
          },
        },
        {
          path: "/config",
          name: "config",
          component: () => import("@/views/pages/Config/index.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "config", active: true },
            ],
            pageTitle: "Configurations",
            rule: new AclRule("ADMIN").generate(),
            middleware: auth,
          },
        },
        {
          path: "/users",
          name: "users",
          component: () => import("@/views/pages/User/DataTable.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "User", active: true },
            ],
            pageTitle: "User",
            rule: new AclRule("ADMIN")
              .or("ACCOUNTANT")
              .or("SUBACCOUNTANT")
              .or("COMMERCIAL")
              .generate(),
            middleware: auth,
          },
        },
        {
          path: "/billing",
          name: "Billing",
          component: () => import("@/views/pages/Billing/DataTable.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Billing", active: true },
            ],
            pageTitle: "Billing",
            rule: new AclRule("ADMIN")
              .or("DRIVER")
              .or("ACCOUNTANT")
              .or("SUBACCOUNTANT")
              .or("CUSTOMER")
              .or("HOUSEKEEPER")
              .generate(),
            middleware: auth,
          },
        },
        {
          path: "/user-requests",
          name: "Demandes",
          component: () => import("@/views/pages/UserRequests/DataTable.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Demandes", active: true },
            ],
            pageTitle: "Demandes",
            rule: new AclRule("ADMIN")
              .or("DRIVER")
              .or("ACCOUNTANT")
              .or("SUBACCOUNTANT")
              .or("CUSTOMER")
              .or("HOUSEKEEPER")
              .generate(),
            middleware: auth,
          },
        },
        {
          path: "/team",
          name: "Team",
          component: () => import("@/views/pages/Team/DataTable.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Team", active: true },
            ],
            pageTitle: "Team",
            rule: new AclRule("CUSTOMER").generate(),
            middleware: auth,
          },
        },
        {
          path: "/invoice",
          name: "invoice",
          component: () => import("./views/pages/Billing/invoice.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Invoice", active: true },
            ],
            pageTitle: "Invoice",
            rule: new AclRule("ADMIN").or("ACCOUNTANT").generate(),
            middleware: auth,
          },
        },
        {
          path: "/custom-driver-invoice",
          name: "custom-driver-invoice",
          component: () =>
            import("./views/pages/Billing/CustomDriverInvoice.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Custom Driver Invoice", active: true },
            ],
            pageTitle: "Invoice",
            rule: new AclRule("ADMIN").or("ACCOUNTANT").generate(),
            middleware: auth,
          },
        },
        {
          path: "/print",
          name: "Print",
          component: () => import("@/views/pages/Print/DataTable.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "print", active: true },
            ],
            pageTitle: "Print",
            rule: new AclRule("ADMIN")
              .or("HOUSEKEEPER")
              .or("COMMERCIAL")
              .or("ACCOUNTANT")
              .or("SUBACCOUNTANT")
              .or("DRIVER")
              .or("BACKOFFICE")
              .or("SUPPORT")
              .or("CUSTOMER")
              .generate(),
            middleware: auth,
          },
        },
        {
          path: "/subdrivers",
          name: "subdrivers",
          component: () => import("@/views/pages/SubDrivers/DataTable.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Livreurs", active: true },
            ],
            pageTitle: "Livreurs",
            manager: true,
            rule: new AclRule("DRIVER").or("HOUSEKEEPER").generate(),
            middleware: auth,
          },
        },
        {
          path: "/slip",
          name: "slip",
          component: () => import("@/views/pages/Print/slip.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Print", active: "/print" },
              { title: "Receipt", active: true },
            ],
            pageTitle: "Print",
            rule: new AclRule("ADMIN")
              .or("ACCOUNTANT")
              .or("HOUSEKEEPER")
              .or("BACKOFFICE")
              .or("SUPPORT")
              .generate(),
            middleware: auth,
          },
        },
        {
          path: "/house",
          name: "House",
          component: () => import("@/views/pages/House/DataTable.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "House", active: true },
            ],
            pageTitle: "House",
            rule: new AclRule("ADMIN")
              .or("ACCOUNTANT")
              .or("SUBACCOUNTANT")
              .or("HOUSEKEEPER")
              .or("SUBACCOUNTANT")
              .or("BACKOFFICE")
              .or("SUPPORT")
              .generate(),
            middleware: auth,
          },
        },
        {
          path: "/warehouses",
          name: "warehouses",
          component: () => import("@/views/pages/Warehouses/DataTable.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Warehouses", active: true },
            ],
            pageTitle: "Warehouses",
            rule: new AclRule("ADMIN").generate(),
            middleware: auth,
          },
        },
        {
          path: "/pallets",
          name: "pallets",
          component: () => import("@/views/pages/Pallets/DataTable.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Pallets", active: true },
            ],
            pageTitle: "Pallets",
            rule: new AclRule("ADMIN")
              .or("HOUSEKEEPER")
              .or("ACCOUNTANT")
              .or("SUBACCOUNTANT")
              .or("DRIVER")
              .or("TRANSPORTOR")
              .or("BACKOFFICE")
              .or("SUPPORT")
              .generate(),
            middleware: auth,
          },
        },
      ],
    },
    {
      path: "/confirmation",
      name: "Confirmation",
      component: () => import("@/views/pages/Confirmation.vue"),
      meta: {
        middleware: log,
        rule: "public",
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/pages/Login.vue"),
      meta: {
        middleware: log,
        rule: "public",
      },
      children: [
        {
          path: "auth",
          name: "auth-google",
          meta: {
            middleware: log,
            rule: "public",
          },
          component: () => import("@/views/pages/Login.vue"),
        },
      ],
    },
    {
      path: "/tracking",
      name: "Tracking",
      component: () => import("@/views/pages/Tracking.vue"),
      meta: {
        middleware: log,
        rule: "public",
      },
    },
    {
      path: "/signup",
      name: "signup",
      component: () => import("@/views/pages/Login.vue"),
      meta: {
        middleware: log,
        rule: "public",
      },
    },
    {
      path: "/password-reset",
      name: "password-reset",
      component: () => import("@/views/pages/Login.vue"),
      meta: {
        rule: "public",
        middleware: log,
      },
    },
    {
      path: "/change-password",
      name: "change-password",
      component: () => import("@/views/pages/Login.vue"),
      meta: {
        rule: "public",
        middleware: log,
      },
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================

        {
          path: "/pages/error-404",
          name: "pageError404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "public",
            middleware: log,
          },
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404",
    },
  ],
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Than run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});
export default router;
